<template>
    <LogoBar />
    <div class="privacy-container">
        <h1>Privacy Policy</h1>
        <p>Last updated: October 28, 2024</p>
        <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your
            information when You use the Service and tells You about Your privacy rights and how the law protects You.
        </p>
        <p>We use Your Personal Data to provide and improve the Service. By using the Service, You agree to the
            collection and use of information in accordance with this Privacy Policy.</p>

        <h2>1. Interpretation and Definitions</h2>
        <h3>1.1 Interpretation</h3>
        <p>The words of which the initial letter is capitalized have meanings defined under the following conditions.
            The following definitions shall have the same meaning regardless of whether they appear in singular or in
            plural.</p>

        <h3>1.2 Definitions</h3>
        <p>For the purposes of this Privacy Policy:</p>
        <ul>
            <li><strong>Account</strong> means a unique account created for You to access our Service or parts of our
                Service.</li>
            <li><strong>Affiliate</strong> means an entity that controls, is controlled by, or is under common control
                with a party, where "control" means ownership of 50% or more of the shares, equity interest, or other
                securities entitled to vote for election of directors or other managing authority.</li>
            <li><strong>Application</strong> refers to easYsim, the software program provided by the Company.</li>
            <li><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement)
                refers to Woodward Inc., 1081 Woodward Way, CO 80524.</li>
            <li><strong>Country</strong> refers to Colorado, United States.</li>
            <li><strong>Device</strong> means any device that can access the Service such as a computer, cellphone, or
                digital tablet.</li>
            <li><strong>Personal Data</strong> is any information that relates to an identified or identifiable
                individual.</li>
            <li><strong>Service</strong> refers to the Application.</li>
            <li><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of
                the Company.</li>
            <li><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the
                Service or from the Service infrastructure itself (for example, the duration of a page visit).</li>
            <li><strong>You</strong> mean the individual accessing or using the Service, or the company, or other legal
                entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
        </ul>

        <h2>2. Collecting and Using Your Personal Data</h2>
        <h3>2.1 Types of Data Collected</h3>

        <h4>2.1.1 Personal Data</h4>
        <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that
            can be used to contact or identify You. Personally identifiable information may include, but is not limited
            to:</p>
        <ul>
            <li>Email address</li>
            <li>Company name</li>
            <li>Phone number</li>
            <li>Usage Data</li>
        </ul>

        <h4>2.1.2 Usage Data</h4>
        <p>Usage Data is collected automatically when using the Service.</p>
        <p>Usage Data may include information such as Your Device's Internet Protocol address (e.g., IP address),
            browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the
            time spent on those pages, unique device identifiers, and other diagnostic data.</p>
        <p>When You access the Service by or through a mobile device, we may collect certain information automatically,
            including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP
            address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use,
            unique device identifiers and other diagnostic data.</p>

        <h3>2.2 Legal Basis for Processing Personal Data</h3>
        <p>We process Your Personal Data on the following legal bases:</p>
        <ul>
            <li><strong>Consent:</strong> Where You have given explicit consent for processing Your Personal Data for
                specific purposes, such as receiving marketing communications.</li>
            <li><strong>Contract Performance:</strong> Where the processing is necessary for the performance of a
                contract to which You are a party (e.g., to provide the Service You requested).</li>
            <li><strong>Legitimate Interests:</strong> Where the processing is necessary for Our legitimate interests,
                such as improving Our Service, provided that Your interests or fundamental rights do not override these
                interests.</li>
            <li><strong>Legal Obligations:</strong> Where the processing is necessary for compliance with a legal
                obligation to which We are subject (e.g., legal or regulatory requirements).</li>
        </ul>

        <h3>2.3 Use of Your Personal Data</h3>
        <p>The Company may use Personal Data for the following purposes:</p>
        <ul>
            <li>To provide and maintain our Service, including to monitor the usage of our Service.</li>
            <li>To manage Your Account: to manage Your registration as a user of the Service.</li>
            <li>For the performance of a contract: the development, compliance and undertaking of the purchase contract
                for the products, items or services You have purchased or of any other contract with Us through the
                Service.</li>
            <li>To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic
                communication, such as a mobile application's push notifications regarding updates or informative
                communications related to the functionalities, products or contracted services, including the security
                updates, when necessary or reasonable for their implementation.</li>
            <li>To provide You with news, special offers and general information about other goods, services and events
                which we offer that are similar to those that you have already purchased or enquired about unless You
                have opted not to receive such information.</li>
            <li>To manage Your requests: To attend and manage Your requests to Us.</li>
            <li>For business transfers: We may use Your information to evaluate or conduct a merger, divestiture,
                restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, in
                which Personal Data held by Us about our Service users is among the assets transferred.</li>
            <li>For other purposes: We may use Your information for other purposes, such as data analysis, identifying
                usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our
                Service, products, services, marketing and your experience.</li>
        </ul>

        <h3>2.4 Use of Cookies and Tracking Technologies</h3>
        <p>We use cookies and similar tracking technologies to track the activity on Our Service and store certain
            information. Tracking technologies used are beacons, tags, and scripts to collect and track information and
            to improve and analyze Our Service.</p>
        <p>
            A cookie is a small file stored on your computer by websites you visit, used to remember your preferences and actions (like login details or language settings). It helps improve your experience by personalizing content, but can also track browsing habits for advertising purposes.</p>
        <p>For more detailed information about the cookies we use, please refer to our <a href="" @click.prevent="$CookieConsent.show(true)">Cookie Policy</a></p>
        <p>Types of Cookies We Use:</p>
        <ul>
            <li><strong>Essential Cookies:</strong> Necessary for the functioning of the website.</li>
            <li><strong>Performance Cookies:</strong> To collect information on how users interact with the Service.
            </li>
            <li><strong>Personalization Cookies:</strong> For remembering user choices (e.g., last scenario selection).
            </li>
        </ul>
        <p>You can manage your cookie preferences by adjusting your browser settings or using our cookie management
            tool.</p>

        <h3>2.5 Transfer of Your Personal Data to Third Countries</h3>
        <p>Your information, including Personal Data, may be transferred to — and maintained on — computers located
            outside of Your state, province, country, or other governmental jurisdiction where the data protection laws
            may differ from those of Your jurisdiction.</p>
        <p>If We transfer Your Personal Data to a country outside the European Economic Area (EEA), We ensure that
            appropriate safeguards are in place, such as:</p>
        <ul>
            <li>Standard Contractual Clauses (SCCs) approved by the European Commission.</li>
            <li>Binding Corporate Rules (BCRs).</li>
            <li>Other appropriate safeguards, such as Privacy Shield certification (if applicable).</li>
        </ul>

        <h3>2.6 Security of Your Personal Data</h3>
        <p>We take appropriate security measures to protect Your Personal Data from unauthorized access, alteration,
            disclosure, or destruction. These measures include:</p>
        <ul>
            <li>Encryption: Data is encrypted both in transit and at rest where necessary.</li>
            <li>Pseudonymization: We may pseudonymize Your data to minimize the impact in the event of unauthorized
                access.</li>
            <li>Access Control: Strict access control mechanisms are in place, ensuring that only authorized personnel
                have access to Your data.</li>
            <li>Regular Security Audits: We conduct regular audits and assessments of Our systems to ensure compliance
                with security standards.</li>
        </ul>
        <p>Please note that no method of transmission over the Internet, or method of electronic storage is 100% secure.
            While We strive to use commercially acceptable means to protect Your Personal Data, we cannot guarantee its
            absolute security.</p>

        <h3>2.7 Disclosure of Your Personal Data</h3>
        <p>We may disclose Your Personal Data in the following situations:</p>
        <ul>
            <li><strong>Business Transactions:</strong> If the Company is involved in a merger, acquisition, or asset
                sale, Your Personal Data may be transferred.</li>
            <li><strong>Law Enforcement:</strong> Under certain circumstances, We may be required to disclose Your
                Personal Data if required by law or in response to valid requests by public authorities.</li>
            <li><strong>Other Legal Requirements:</strong> The Company may disclose Your Personal Data in the good faith
                belief that such action is necessary to comply with legal obligations, protect Our rights or property,
                or ensure the safety of the Service’s users.</li>
        </ul>

        <h3>2.8 Retention of Your Personal Data</h3>
        <p>We will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy
            Policy. We will retain and use Your Personal Data to comply with legal obligations, resolve disputes, and
            enforce our agreements.</p>

        <h3>2.9 Third-Party Services</h3>
        <p>To provide full functionality and ensure the security of our Service, we utilize third-party service
            providers. Below are the details of these providers and the scope in which they process your personal data:
        </p>

        <h4>2.9.1 Skytap</h4>
        <p>We use Skytap, a provider of virtual machines, which are used to run simulations as part of our Service.
            Skytap may process certain technical data, such as IP addresses and usage data, to ensure the proper
            operation of the virtual environments.</p>
        <p>For more information on Skytap data processing practices, please refer to their privacy policy: <a
                href="https://www.skytap.com/terms/privacy-policy/" target="_blank">Skytap Privacy Policy</a>.</p>

        <h4>2.9.2 Firebase</h4>
        <p>We use Firebase, a platform provided by Google, for authentication and database services. Firebase processes
            personal data, such as email addresses and user activity data, to enable user authentication, manage
            accounts, and store data related to the usage of our Service.</p>
        <p>Firebase complies with GDPR requirements and uses industry-standard security measures, including encryption.
            For more information on Firebase’s data practices, please refer to their privacy policy: <a
                href="https://firebase.google.com/support/privacy" target="_blank">Firebase Privacy Policy</a>.</p>

        <h2>3. Links to Other Websites</h2>
        <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third-party
            link, you will be directed to that third party's site. We strongly advise You to review the Privacy Policy
            of every site You visit.</p>
        <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any
            third-party sites or services.</p>

        <h2>4. Changes to This Privacy Policy</h2>
        <p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new
            Privacy Policy on this page.</p>

        <h2>5. Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, You can contact us:</p>
        <ul>
            <li>By email: <a href="mailto:industrial.salesPG@woodward.com">industrial.salesPG@woodward.com</a></li>
            <li>By visiting this page on our website: <a
                    href="https://easygen.org/contact">https://easygen.org/contact</a></li>
        </ul>
    </div>
</template>

<script>
import LogoBar from '@/components/navbar/LogoBar.vue'

export default {
    components: {
        LogoBar
    }
}
</script>

<style scoped>
.privacy-container {
    color: var(--font1);
    padding: 0 1rem;
    word-break: normal;
    width: 90%;
}

a {
    color: var(--blue1) !important;
}
</style>